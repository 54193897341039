import React, { useEffect, useState, useCallback } from "react"
import { graphql } from "gatsby"

import {
  Layout,
  useTranslate,
} from "@solublestudio/gatsby-theme-soluble-source"

import Section from "../sections"

export default function SearchPage({ pageContext, data, ...props }) {
  const [term, setTerm] = useState()

  const [basePosts, setBasePosts] = useState(null)
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true)
  const [language, setLanguage] = useState(pageContext.language)
  const translate = useTranslate()

  const getPosts = (searchTerm) => {
    if (!basePosts) {
      return
    }

    setLoading(true)

    let URL = `https://site-api.datocms.com/search-results?q=${searchTerm}&locale=${language}`

    if (process.env.GATSBY_BUILD_ID) {
      URL += `&build_trigger_id=${process.env.GATSBY_BUILD_ID}`
    }

    fetch(URL, {
      headers: {
        Accept: "application/json",
        "X-Api-Version": "3",
        Authorization: `Bearer ${process.env.GATSBY_DATOCMS_SITE_SEARCH_API_KEY}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        const { data } = json

        const finalData = data
          ?.map((post) => {
            const basePost = basePosts.find((item) => {
              return post?.attributes?.url?.includes(item.slug)
            })
            return basePost ?? null
          })
          .filter((el) => !!el)

        if (finalData && finalData.length > 0) {
          setPosts(finalData)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (term && basePosts) {
      getPosts(term)
    }
  }, [term, basePosts])

  useEffect(() => {
    const dataKeys = Object.keys(data)

    let finalData = []

    if (dataKeys?.length) {
      dataKeys.forEach((key) => {
        if (data[key]?.edges)
          return (finalData = [...finalData, ...data[key].edges])
      })

      finalData = finalData.map((item) => item.node)
    }

    setBasePosts(finalData)
  }, [data])

  useEffect(() => {
    if (props.location.search) {
      let paramsArray = props.location.search.split("?")[1].split("&")
      let params = {}

      paramsArray.map((res) => {
        let aux = res.split("=")
        params[aux[0]] = aux[1]
      })

      if (params.term) {
        setTerm(params.term)
        return
      }
    }

    setLoading(false)
  }, [props.location.search])

  const getTitle = useCallback(() => {
    return term ? term : data?.page?.title
  }, [term, data])

  const getHintText = useCallback(() => {
    let finalText = translate["blog.input.hint"].text ?? ""

    const data = {
      results: posts?.length,
      term,
    }

    Object.keys({
      results: posts?.length,
      term,
    }).forEach((key) => {
      finalText = finalText.replace(`:${key}`, data[key])
    })

    return finalText
  }, [term, data])

  return (
    <Layout
      navbarFixed={true}
      navbarTransparent={true}
      footerExtraParams={{
        alternateLanguages: pageContext.seo?.alternateLanguages,
      }}
      headerExtraParams={{
        alternateLanguages: pageContext.seo?.alternateLanguages,
        url: pageContext?.pageUrl,
      }}
    >
      {data.parentPage?.sections?.map((section, i) => (
        <Section
          key={i}
          order={i + 1}
          isLast={i + 1 === data.parentPage.sections.length}
          {...(section.__typename === "DatoCmsBlogSection"
            ? {
                ...section,
                title: getTitle(),
                subtitle: data.parentPage?.sections[0]?.subtitle,
                posts,
                pageNr: pageContext.pageNr,
                term,
                hintText: getHintText(),
                currentSlug: data?.page?.slug,
                defaultTagText: translate["blog.tag.default"]?.text,
                emptyText: translate["blog.empty.text"]?.text,
                isLoading: loading,
                loadingText: translate["blog.loading.label"]?.text,
                firstPageHref: data?.parentPage?.slug,
              }
            : section)}
        />
      ))}
    </Layout>
  )
}

export const query = graphql`
  query Search($id: String, $language: String) {
    page: datoCmsPage(id: { eq: $id }, locale: { eq: $language }) {
      title
      slug
    }
    posts: allDatoCmsBlog(filter: { locale: { eq: $language } }) {
      edges {
        node {
          ...PostItem
        }
      }
    }
    parentPage: getParentPage(locale: $language, type: "DatoCmsBlogSection") {
      ...DatoCmsBlogSectionParentPage
    }
  }
`
